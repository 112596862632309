import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  LiveMessage,
  LiveUpdateDisconnectFn,
  LiveUpdateSubscription,
  FAST_FORWARD_ROOMS,
} from './live-update.interface';

import { SnackMessageService } from '../snack-message';
import { O8PipelineEventStatus, O8PipelineEventUpdate } from 'common.interfaces';
import { applyEventToSubs, getCleanupTrackIds, showPersistedEventError } from './live-update.utils';
import { SocketHandlerService } from './socket-handler.service';
import { Observable, filter, first, map } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LiveUpdateService {
  trackedIds = [] as string[];

  constructor(
    private socketHandlerService: SocketHandlerService,
    private snackMessageService: SnackMessageService,
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.socketHandlerService.setupSocketConnection(this.onMessage.bind(this));
    }
  }

  getLiveStatus$() {
    return this.socketHandlerService.getLiveStatus$();
  }

  pushTrackId(trackId: string | undefined) {
    if (!trackId) {
      return;
    }
    this.trackedIds = [...this.trackedIds, trackId];
  }

  addLiveSubscription(liveSub: LiveUpdateSubscription<any>): Observable<LiveUpdateDisconnectFn> {
    return this.getLiveStatus$().pipe(
      filter((isLive) => !!isLive),
      first(),
      map(() => {
        return this.socketHandlerService.addLiveSubscription(liveSub);
      }),
    );
  }

  onMessage({ room, data }: LiveMessage) {
    if (FAST_FORWARD_ROOMS.includes(room)) {
      applyEventToSubs(data, this.socketHandlerService.getJoinedRooms().get(room));
      return;
    }
    const evt = data as O8PipelineEventUpdate;
    const showErrorOnPersistedEvent = showPersistedEventError(evt, this.trackedIds);
    if (showErrorOnPersistedEvent) {
      this.snackMessageService.showError(
        `Something wrong occured while trying to save your changes. Please refresh the page and try again. If the problem persists, please reach out to support.`,
      );
      return;
    }
    this.trackedIds = getCleanupTrackIds(evt, this.trackedIds);
    const canApplyOnEventFn = evt.status === O8PipelineEventStatus.PERSISTED && !!evt.eventMetadata.trackId;
    if (canApplyOnEventFn) {
      applyEventToSubs(evt, this.socketHandlerService.getJoinedRooms().get(room));
    }
  }
}
