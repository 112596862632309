import { Injectable } from '@angular/core';
import { LiveMessage, LiveRoomSub, LiveUpdateDisconnectFn, LiveUpdateSubscription } from './live-update.interface';
import { STREAMING_MESSAGE, STREAMING_ROOM } from 'common.interfaces';
import { getSocketOptions, getWsReconnectTimeout } from './live-update.utils';
import { O8ConfigService } from '../config';
import { UserService } from '../login/providers';
import { BehaviorSubject, first, tap, Observable } from 'rxjs';
import { SocketIoWrapper } from './socket-io.wrapper';
import type { Socket } from 'socket.io-client';
import { withoutTrailingSlash } from '@origin8-web/o8-utils/format';

@Injectable({
  providedIn: 'root',
})
export class SocketHandlerService {
  trackedIds = [] as string[];
  socket!: Socket<any, any>;
  private joinedRooms: LiveRoomSub = new Map();
  attemptCount = 0;
  private isLive$ = new BehaviorSubject<boolean>(false);

  constructor(
    private o8ConfigService: O8ConfigService<{ gatewayStreamingUrl: string }>,
    private userService: UserService,
    private socketIoWrapper: SocketIoWrapper,
  ) {}

  pushTrackId(trackId: string) {
    this.trackedIds = [...this.trackedIds, trackId];
  }

  configureSocket(socket: Socket<any, any>, onMessageFn: (evt: LiveMessage) => void) {
    socket.on(STREAMING_MESSAGE.CONNECT, this.onConnect.bind(this));
    socket.on(STREAMING_MESSAGE.CONNECT_ERROR, () => {
      this.attemptCount = this.attemptCount + 1;
      this.reconnectSocketWithTimeout(this.attemptCount, onMessageFn);
    });
    socket.on(STREAMING_MESSAGE.ROOM_JOINED, (room: string) => {
      console.log(`Joined room ${room}`);
    });
    socket.on(STREAMING_MESSAGE.NO_SUCH_ROOM, (room: string) => {
      console.log(`Room does not exist ${room}`);
    });
    socket.on(STREAMING_MESSAGE.ROOM_ALREADY_JOINED, (room: string) => {
      console.log(`Room ${room} was already joined`);
    });
    socket.on(STREAMING_MESSAGE.DISCONNECT, this.onDisconnect.bind(this));
    socket.on(STREAMING_MESSAGE.EVENT, (evt: any) => {
      onMessageFn(evt);
    });
  }

  onConnect() {
    console.log(`Ws Socket connected`);
    this.attemptCount = 0; /* Once connection is succesfull we reset the reconnect count */
    this.rejoinAllRooms();
    this.isLive$.next(true);
  }

  onDisconnect(reason: string) {
    this.isLive$.next(false);
    console.log(`Ws disconnect: ${reason}`);
  }

  setupSocketConnection(onMessageFn: (evt: LiveMessage) => void) {
    this.userService
      .getAccessToken$()
      .pipe(
        first(),
        tap((accessToken) => {
          console.log('Ws socket connection attempt');
          this.socket = this.socketIoWrapper.getNewSocket(
            withoutTrailingSlash(this.o8ConfigService.get('gatewayStreamingUrl') as string),
            getSocketOptions(accessToken),
          );
          this.configureSocket(this.socket, onMessageFn);
        }),
      )
      .subscribe();
  }

  reconnectSocketWithTimeout(attemptCount: number, onMessageFn: (evt: LiveMessage) => void) {
    if (this.socket.active) {
      this.socket.close();
      this.isLive$.next(false);
    }
    const timeout = getWsReconnectTimeout(attemptCount);
    console.warn(`Ws connection error, retrying to connect in ${timeout / 1000} seconds`);
    setTimeout(() => {
      this.setupSocketConnection(onMessageFn);
    }, timeout);
  }

  joinRoom(room: STREAMING_ROOM) {
    console.log(`Trying to join room ${room}`);
    this.socket.emit(STREAMING_MESSAGE.JOIN, room);
  }

  getJoinedRooms() {
    return this.joinedRooms;
  }

  rejoinAllRooms() {
    for (const room of this.joinedRooms.keys()) {
      this.joinRoom(room);
    }
  }

  getLiveStatus$(): Observable<boolean> {
    return this.isLive$;
  }

  getLiveStatus(): boolean {
    return this.isLive$.value;
  }

  setLiveStatus(status: boolean) {
    this.isLive$.next(status);
  }

  addLiveSubscription(liveSub: LiveUpdateSubscription): LiveUpdateDisconnectFn {
    if (!this.isLive$.value) {
      throw new Error(`Cannot add subscription when ws connection is not up`);
    }
    if (!this.joinedRooms.has(liveSub.room)) {
      this.joinRoom(liveSub.room);
      this.joinedRooms.set(liveSub.room, new Set());
    }
    const roomSubs = this.joinedRooms.get(liveSub.room) as Set<LiveUpdateSubscription>;
    roomSubs.add(liveSub);
    return this.cleanSubscription.bind(this, liveSub, roomSubs);
  }

  cleanSubscription(liveSub: LiveUpdateSubscription, roomSubs: Set<LiveUpdateSubscription>) {
    roomSubs.delete(liveSub);
    if (roomSubs?.size === 0) {
      this.joinedRooms.delete(liveSub.room);
      this.socket.emit(STREAMING_MESSAGE.LEAVE, liveSub.room);
    }
    console.log(`Room left ${liveSub.room}`);
  }
}
