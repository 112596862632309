import { Injectable } from '@angular/core';
import { io, type ManagerOptions, type SocketOptions } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketIoWrapper {
  getNewSocket(uri: string, opts?: Partial<ManagerOptions & SocketOptions> | undefined) {
    return io(uri, opts);
  }
}
