import { STREAMING_ROOM } from 'common.interfaces';

export interface LiveUpdateSubscription<T = any> {
  room: STREAMING_ROOM;
  filter?: (evt: T) => boolean;
  onEvent: (
    evt: T,
  ) => void /* Will be triggered only if the event passes the filter function, if no filter function, it occurs on every event in the room */;
}

export type LiveUpdateDisconnectFn = () => void;
export type LiveRoomSub = Map<STREAMING_ROOM, Set<LiveUpdateSubscription<any>>>;

export interface LiveMessage<T = any> {
  room: STREAMING_ROOM;
  data: T;
}

export const FAST_FORWARD_ROOMS = [
  STREAMING_ROOM.ADVOCATE_STATUS,
  STREAMING_ROOM.LIVE_CALL_STATUS,
  STREAMING_ROOM.INCOMING_WARM_TRANSFER,
];
